import React, { useRef, useEffect } from "react"
import anime from "animejs"
import "./honeycomb_bg.scss"

const HoneycombBG = () => {
  useEffect(() => {
    anime({
      targets: ".st1",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 4500,
      delay: function (el, i) {
        return i * 250
      },
      direction: "alternate",
      loop: true,
    })
  }, [])

  return (
    //<?xml version="1.0" encoding="utf-8"?>
    //<!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 775 501"
    >
      <g id="HoneyComb-st0-CenterRight">
        <polygon
          className="st0"
          points="445.12,107.93 445.06,107.96 445.06,135.96 469.31,149.96 493.3,136.11 493.3,108.13 493.56,107.99 
            493.56,107.96 469.96,94.34 445.76,108.31 	"
        />
        <polygon
          className="st0"
          points="493.3,136.11 493.3,136.13 517.55,150.13 517.74,150.03 517.74,149.74 541.8,135.84 541.8,108.13 
            517.55,94.13 493.56,107.99 493.56,135.96 	"
        />
        <polygon
          className="st0"
          points="517.74,150.03 517.74,177.74 541.99,191.74 566.23,177.74 566.23,149.74 541.99,135.74 541.8,135.84 
            541.8,136.13 	"
        />
        <polygon
          className="st0"
          points="445.41,52.51 445.28,52.43 445.28,24.53 421.33,10.7 397.08,24.7 397.08,52.7 421.33,66.7 
            421.52,66.6 421.52,66.31 	"
        />
        <polygon
          className="st0"
          points="493.78,24.45 493.78,24.43 469.53,10.43 445.28,24.43 445.28,24.53 445.58,24.7 445.58,52.41 
            445.76,52.31 469.88,66.23 493.53,52.58 493.53,24.6 	"
        />
        <polygon
          className="st0"
          points="445.58,52.6 445.58,52.7 421.52,66.6 421.52,94.31 445.12,107.93 469.31,93.96 469.96,94.34 
            470.01,94.31 470.01,66.31 469.88,66.23 469.53,66.43 	"
        />
        <polygon
          className="st0"
          points="445.58,52.6 445.58,52.41 445.41,52.51 	"
        />
        <polygon
          className="st0"
          points="541.86,52.41 541.73,52.33 541.73,24.43 517.77,10.6 493.78,24.45 493.78,52.43 493.53,52.58 
            493.53,52.6 517.77,66.6 517.96,66.5 517.96,66.21 	"
        />
        <polygon
          className="st0"
          points="590.22,24.33 565.98,10.33 541.73,24.33 541.73,24.43 542.02,24.6 542.02,52.31 542.21,52.21 
            566.33,66.13 590.22,52.33 	"
        />
        <polygon
          className="st0"
          points="542.02,52.5 542.02,52.6 517.96,66.5 517.96,94.21 542.21,108.21 566.46,94.21 566.46,66.21 
            566.33,66.13 565.98,66.33 	"
        />
        <polygon
          className="st0"
          points="542.02,52.5 542.02,52.31 541.86,52.41 	"
        />
      </g>
      <polygon
        id="HoneyComb-st0-Lone"
        className="st0"
        points="300.14,275.34 300.14,303.34 324.39,317.34 348.64,303.34 348.64,275.34 324.39,261.34 "
      />
      <g id="HoneyComb-st0-CenterLeft">
        <polygon
          className="st0"
          points="373.89,95.59 373.89,67.88 349.64,53.88 325.39,67.88 325.39,95.88 349.64,109.88 349.82,109.78 
            349.82,109.49 	"
        />
        <polygon
          className="st0"
          points="374.07,95.49 373.89,95.59 373.89,95.88 349.82,109.78 349.82,137.49 374.07,151.49 398.32,137.49 
            398.32,109.49 	"
        />
        <polygon
          className="st0"
          points="276.72,95.69 276.59,95.61 276.59,67.71 252.76,53.95 228.64,67.88 228.51,67.81 228.39,67.88 
            228.39,95.88 252.64,109.88 252.82,109.78 252.82,109.49 	"
        />
        <polygon
          className="st0"
          points="276.84,67.61 276.72,67.54 276.59,67.61 276.59,67.71 276.89,67.88 276.89,95.59 277.07,95.49 
            301.19,109.41 325.09,95.61 325.09,67.61 300.96,53.68 	"
        />
        <polygon
          className="st0"
          points="276.89,95.78 276.89,95.88 252.82,109.78 252.82,137.49 277.07,151.49 301.32,137.49 301.32,109.49 
            301.19,109.41 300.84,109.61 	"
        />
        <polygon
          className="st0"
          points="276.89,95.78 276.89,95.59 276.72,95.69 	"
        />
        <polygon
          className="st0"
          points="252.76,53.95 252.89,53.88 252.89,53.78 252.59,53.61 252.59,25.71 228.64,11.88 204.39,25.88 
            204.39,53.88 228.51,67.81 252.64,53.88 	"
        />
        <polygon
          className="st0"
          points="252.89,53.78 276.72,67.54 300.84,53.61 300.96,53.68 301.09,53.61 301.09,25.61 276.84,11.61 
            252.59,25.61 252.59,25.71 252.89,25.88 	"
        />
      </g>
      <g id="HoneyComb-st0-Left">
        <polygon
          className="st0"
          points="60.14,24.64 36.19,10.81 11.94,24.81 11.94,52.81 36.19,66.81 60.44,52.81 60.44,52.71 60.14,52.54 	
            "
        />
        <polygon
          className="st0"
          points="84.39,10.54 60.14,24.54 60.14,24.64 60.44,24.81 60.44,52.71 84.39,66.54 108.64,52.54 108.64,24.54 
                "
        />
      </g>
      <g id="HoneyComb-st0-Right">
        <polygon
          className="st0"
          points="614.18,150.89 614.18,150.6 638.08,136.81 637.95,136.73 637.95,108.83 614,95 589.75,109 589.75,137 
            614,151 	"
        />
        <polygon
          className="st0"
          points="638.25,136.71 638.43,136.6 662.55,150.53 686.19,136.88 686.19,108.9 686.45,108.75 686.45,108.73 
            662.85,95.1 638.65,109.07 638.01,108.7 637.95,108.73 637.95,108.83 638.25,109 	"
        />
        <polygon
          className="st0"
          points="638.25,136.9 638.25,137 614.18,150.89 614.18,178.6 638.43,192.6 662.68,178.6 662.68,150.6 
            662.55,150.53 662.2,150.73 	"
        />
        <polygon
          className="st0"
          points="638.25,136.71 638.08,136.81 638.25,136.9 	"
        />
        <polygon
          className="st0"
          points="686.19,136.88 686.19,136.9 710.44,150.9 710.63,150.79 710.63,150.5 734.53,136.7 734.4,136.63 
            734.4,108.73 710.44,94.9 686.45,108.75 686.45,136.73 	"
        />
        <polygon
          className="st0"
          points="734.69,136.61 734.88,136.5 758.99,150.43 782.89,136.63 782.89,108.63 759.29,95 735.1,108.97 
            734.45,108.6 734.4,108.63 734.4,108.73 734.69,108.9 	"
        />
        <polygon
          className="st0"
          points="734.69,136.8 734.69,136.9 710.63,150.79 710.63,178.5 734.88,192.5 759.12,178.5 759.12,150.5 
            758.99,150.43 758.64,150.63 	"
        />
        <polygon
          className="st0"
          points="734.69,136.61 734.53,136.7 734.69,136.8 	"
        />
        <polygon
          className="st0"
          points="662.85,95.1 662.9,95.07 662.9,67.07 638.65,53.07 614.41,67.07 614.41,95.07 638.01,108.7 
            662.2,94.73 	"
        />
        <polygon
          className="st0"
          points="734.75,53.17 734.62,53.1 734.62,25.2 710.66,11.37 686.42,25.37 686.42,53.37 710.66,67.37 
            710.85,67.26 710.85,66.97 	"
        />
        <polygon
          className="st0"
          points="734.91,53.08 735.1,52.97 759.21,66.9 783.11,53.1 783.11,25.1 758.87,11.1 734.62,25.1 734.62,25.2 
            734.91,25.37 	"
        />
        <polygon
          className="st0"
          points="759.29,95 759.35,94.97 759.35,66.97 759.21,66.9 758.87,67.1 734.91,53.27 734.91,53.37 
            710.85,67.26 710.85,94.97 734.45,108.6 758.64,94.63 	"
        />
        <polygon
          className="st0"
          points="734.75,53.17 734.91,53.27 734.91,53.08 	"
        />
        <polygon
          className="st0"
          points="589.5,304.12 589.5,305.11 613.75,319.11 637.7,305.28 637.7,277.84 638,277.67 638,277.11 
            613.75,263.11 589.75,276.97 589.75,303.97 	"
        />
        <polygon
          className="st0"
          points="637.7,305.28 637.7,305.84 661.95,319.84 686.2,305.84 686.2,277.84 661.95,263.84 638,277.67 
            638,305.11 	"
        />
        <polygon
          className="st0"
          points="686.42,193.33 686.42,193.31 662.17,179.31 637.92,193.31 637.92,221.31 638.06,221.38 638.4,221.18 
            662.52,235.11 686.17,221.46 686.17,193.48 	"
        />
        <polygon
          className="st0"
          points="638.06,221.38 614.16,235.18 614.16,263.18 638.4,277.18 662.65,263.18 662.65,235.18 662.52,235.11 
            662.17,235.31 	"
        />
        <polygon
          className="st0"
          points="710.42,235.48 734.66,221.48 734.66,193.48 710.42,179.48 686.42,193.33 686.42,221.31 686.17,221.46 
            686.17,221.48 	"
        />
        <polygon
          className="st0"
          points="444.81,276.24 444.52,276.42 420.5,262.55 396.61,276.35 396.61,304.35 420.86,318.35 445.11,304.35 
            445.11,304.25 444.81,304.08 	"
        />
        <polygon
          className="st0"
          points="493.31,276.1 493.31,276.08 469.06,262.08 468.76,262.25 468.76,262.42 444.88,276.21 445.11,276.35 
            445.11,304.25 469.06,318.08 493.06,304.22 493.06,276.24 	"
        />
        <polygon
          className="st0"
          points="541.39,304.05 541.26,303.97 541.26,276.14 540.96,276.32 516.94,262.45 493.31,276.1 493.31,304.08 
            493.06,304.22 493.06,304.24 517.3,318.24 517.49,318.14 517.49,317.85 	"
        />
        <polygon
          className="st0"
          points="541.32,276.11 541.55,276.24 541.55,303.95 541.74,303.85 565.86,317.77 589.5,304.12 589.5,277.11 
            589.75,276.97 589.75,275.97 565.51,261.97 565.21,262.15 565.21,262.32 	"
        />
        <polygon
          className="st0"
          points="565.99,345.62 565.99,317.85 565.86,317.77 565.51,317.97 541.55,304.15 541.55,304.24 517.49,318.14 
            517.49,345.85 541.74,359.85 542.15,359.61 542.15,359.38 	"
        />
        <polygon
          className="st0"
          points="541.39,304.05 541.55,304.15 541.55,303.95 	"
        />
        <polygon
          className="st0"
          points="444.81,276.17 444.81,276.08 468.76,262.25 468.76,234.42 444.52,220.42 420.33,234.38 420.33,262.16 
            420.27,262.2 420.27,262.42 420.5,262.55 420.86,262.35 	"
        />
        <polygon
          className="st0"
          points="444.81,276.24 444.88,276.21 444.81,276.17 	"
        />
        <polygon
          className="st0"
          points="541.26,276.07 541.26,275.97 565.21,262.15 565.21,234.32 540.96,220.32 516.71,234.32 516.71,262.32 
            516.94,262.45 517.3,262.24 	"
        />
        <polygon
          className="st0"
          points="541.26,276.07 541.26,276.14 541.32,276.11 	"
        />
        <polygon
          className="st0"
          points="420.33,234.38 420.33,234.16 396.09,220.16 371.84,234.16 371.84,262.16 396.09,276.16 420.27,262.2 
            420.27,234.42 	"
        />
        <polygon
          className="st0"
          points="638.89,388.22 638.89,360.52 614.65,346.52 590.65,360.37 590.65,387.38 590.4,387.52 590.4,388.52 
            614.65,402.52 614.83,402.41 614.83,402.12 	"
        />
        <polygon
          className="st0"
          points="638.89,388.22 638.89,388.52 614.83,402.41 614.83,430.12 639.08,444.12 663.33,430.12 663.33,402.12 
            639.08,388.12 	"
        />
        <polygon
          className="st0"
          points="590.65,360.37 590.65,359.38 566.4,345.38 565.99,345.62 565.99,345.85 542.15,359.61 542.15,387.38 
            566.4,401.38 590.4,387.52 590.4,360.52 	"
        />
        <polygon
          className="st0"
          points="735.11,361 735.11,389 759.36,403 783.61,389 783.61,361 759.36,347 	"
        />
        <polygon
          className="st0"
          points="760.07,431.51 760.07,403.8 735.82,389.8 711.83,403.65 711.83,430.66 711.57,430.81 711.57,431.8 
            735.82,445.8 736.01,445.69 736.01,445.4 	"
        />
        <polygon
          className="st0"
          points="760.26,431.4 760.07,431.51 760.07,431.8 736.01,445.69 736.01,473.4 760.26,487.4 784.5,473.4 
            784.5,445.4 	"
        />
        <polygon
          className="st0"
          points="711.83,403.65 711.83,402.66 687.58,388.66 663.33,402.66 663.33,430.66 687.58,444.66 711.57,430.81 
            711.57,403.8 	"
        />
      </g>
      <g id="HoneyComb-st1-CenterRight">
        <polygon
          className="st1"
          points="445.12,107.93 445.06,107.96 445.06,135.96 469.31,149.96 493.3,136.11 493.3,108.13 493.56,107.99 
		493.56,107.96 469.96,94.34 445.76,108.31 	"
        />
        <polygon
          className="st1"
          points="493.3,136.11 493.3,136.13 517.55,150.13 517.74,150.03 517.74,149.74 541.8,135.84 541.8,108.13 
		517.55,94.13 493.56,107.99 493.56,135.96 	"
        />
        <polygon
          className="st1"
          points="517.74,150.03 517.74,177.74 541.99,191.74 566.23,177.74 566.23,149.74 541.99,135.74 541.8,135.84 
		541.8,136.13 	"
        />
        <polygon
          className="st1"
          points="445.41,52.51 445.28,52.43 445.28,24.53 421.33,10.7 397.08,24.7 397.08,52.7 421.33,66.7 
		421.52,66.6 421.52,66.31 	"
        />
        <polygon
          className="st1"
          points="493.78,24.45 493.78,24.43 469.53,10.43 445.28,24.43 445.28,24.53 445.58,24.7 445.58,52.41 
		445.76,52.31 469.88,66.23 493.53,52.58 493.53,24.6 	"
        />
        <polygon
          className="st1"
          points="445.58,52.6 445.58,52.7 421.52,66.6 421.52,94.31 445.12,107.93 469.31,93.96 469.96,94.34 
		470.01,94.31 470.01,66.31 469.88,66.23 469.53,66.43 	"
        />
        <polygon
          className="st1"
          points="445.58,52.6 445.58,52.41 445.41,52.51 	"
        />
        <polygon
          className="st1"
          points="541.86,52.41 541.73,52.33 541.73,24.43 517.77,10.6 493.78,24.45 493.78,52.43 493.53,52.58 
		493.53,52.6 517.77,66.6 517.96,66.5 517.96,66.21 	"
        />
        <polygon
          className="st1"
          points="590.22,24.33 565.98,10.33 541.73,24.33 541.73,24.43 542.02,24.6 542.02,52.31 542.21,52.21 
		566.33,66.13 590.22,52.33 	"
        />
        <polygon
          className="st1"
          points="542.02,52.5 542.02,52.6 517.96,66.5 517.96,94.21 542.21,108.21 566.46,94.21 566.46,66.21 
		566.33,66.13 565.98,66.33 	"
        />
        <polygon
          className="st1"
          points="542.02,52.5 542.02,52.31 541.86,52.41 	"
        />
      </g>
      <polygon
        id="HoneyComb-st1-Lone"
        className="st1"
        points="300.14,275.34 300.14,303.34 324.39,317.34 348.64,303.34 348.64,275.34 324.39,261.34 "
      />
      <g id="HoneyComb-st1-CenterLeft">
        <polygon
          className="st1"
          points="373.89,95.59 373.89,67.88 349.64,53.88 325.39,67.88 325.39,95.88 349.64,109.88 349.82,109.78 
		349.82,109.49 	"
        />
        <polygon
          className="st1"
          points="374.07,95.49 373.89,95.59 373.89,95.88 349.82,109.78 349.82,137.49 374.07,151.49 398.32,137.49 
		398.32,109.49 	"
        />
        <polygon
          className="st1"
          points="276.72,95.69 276.59,95.61 276.59,67.71 252.76,53.95 228.64,67.88 228.51,67.81 228.39,67.88 
		228.39,95.88 252.64,109.88 252.82,109.78 252.82,109.49 	"
        />
        <polygon
          className="st1"
          points="276.84,67.61 276.72,67.54 276.59,67.61 276.59,67.71 276.89,67.88 276.89,95.59 277.07,95.49 
		301.19,109.41 325.09,95.61 325.09,67.61 300.96,53.68 	"
        />
        <polygon
          className="st1"
          points="276.89,95.78 276.89,95.88 252.82,109.78 252.82,137.49 277.07,151.49 301.32,137.49 301.32,109.49 
		301.19,109.41 300.84,109.61 	"
        />
        <polygon
          className="st1"
          points="276.89,95.78 276.89,95.59 276.72,95.69 	"
        />
        <polygon
          className="st1"
          points="252.76,53.95 252.89,53.88 252.89,53.78 252.59,53.61 252.59,25.71 228.64,11.88 204.39,25.88 
		204.39,53.88 228.51,67.81 252.64,53.88 	"
        />
        <polygon
          className="st1"
          points="252.89,53.78 276.72,67.54 300.84,53.61 300.96,53.68 301.09,53.61 301.09,25.61 276.84,11.61 
		252.59,25.61 252.59,25.71 252.89,25.88 	"
        />
      </g>
      <g id="HoneyComb-st1-Left">
        <polygon
          className="st1"
          points="60.14,24.64 36.19,10.81 11.94,24.81 11.94,52.81 36.19,66.81 60.44,52.81 60.44,52.71 60.14,52.54 	
		"
        />
        <polygon
          className="st1"
          points="84.39,10.54 60.14,24.54 60.14,24.64 60.44,24.81 60.44,52.71 84.39,66.54 108.64,52.54 108.64,24.54 
			"
        />
      </g>
      <g id="HoneyComb-st1-Right">
        <polygon
          className="st1"
          points="614.18,150.89 614.18,150.6 638.08,136.81 637.95,136.73 637.95,108.83 614,95 589.75,109 589.75,137 
		614,151 	"
        />
        <polygon
          className="st1"
          points="638.25,136.71 638.43,136.6 662.55,150.53 686.19,136.88 686.19,108.9 686.45,108.75 686.45,108.73 
		662.85,95.1 638.65,109.07 638.01,108.7 637.95,108.73 637.95,108.83 638.25,109 	"
        />
        <polygon
          className="st1"
          points="638.25,136.9 638.25,137 614.18,150.89 614.18,178.6 638.43,192.6 662.68,178.6 662.68,150.6 
		662.55,150.53 662.2,150.73 	"
        />
        <polygon
          className="st1"
          points="638.25,136.71 638.08,136.81 638.25,136.9 	"
        />
        <polygon
          className="st1"
          points="686.19,136.88 686.19,136.9 710.44,150.9 710.63,150.79 710.63,150.5 734.53,136.7 734.4,136.63 
		734.4,108.73 710.44,94.9 686.45,108.75 686.45,136.73 	"
        />
        <polygon
          className="st1"
          points="734.69,136.61 734.88,136.5 758.99,150.43 782.89,136.63 782.89,108.63 759.29,95 735.1,108.97 
		734.45,108.6 734.4,108.63 734.4,108.73 734.69,108.9 	"
        />
        <polygon
          className="st1"
          points="734.69,136.8 734.69,136.9 710.63,150.79 710.63,178.5 734.88,192.5 759.12,178.5 759.12,150.5 
		758.99,150.43 758.64,150.63 	"
        />
        <polygon
          className="st1"
          points="734.69,136.61 734.53,136.7 734.69,136.8 	"
        />
        <polygon
          className="st1"
          points="662.85,95.1 662.9,95.07 662.9,67.07 638.65,53.07 614.41,67.07 614.41,95.07 638.01,108.7 
		662.2,94.73 	"
        />
        <polygon
          className="st1"
          points="734.75,53.17 734.62,53.1 734.62,25.2 710.66,11.37 686.42,25.37 686.42,53.37 710.66,67.37 
		710.85,67.26 710.85,66.97 	"
        />
        <polygon
          className="st1"
          points="734.91,53.08 735.1,52.97 759.21,66.9 783.11,53.1 783.11,25.1 758.87,11.1 734.62,25.1 734.62,25.2 
		734.91,25.37 	"
        />
        <polygon
          className="st1"
          points="759.29,95 759.35,94.97 759.35,66.97 759.21,66.9 758.87,67.1 734.91,53.27 734.91,53.37 
		710.85,67.26 710.85,94.97 734.45,108.6 758.64,94.63 	"
        />
        <polygon
          className="st1"
          points="734.75,53.17 734.91,53.27 734.91,53.08 	"
        />
        <polygon
          className="st1"
          points="589.5,304.12 589.5,305.11 613.75,319.11 637.7,305.28 637.7,277.84 638,277.67 638,277.11 
		613.75,263.11 589.75,276.97 589.75,303.97 	"
        />
        <polygon
          className="st1"
          points="637.7,305.28 637.7,305.84 661.95,319.84 686.2,305.84 686.2,277.84 661.95,263.84 638,277.67 
		638,305.11 	"
        />
        <polygon
          className="st1"
          points="686.42,193.33 686.42,193.31 662.17,179.31 637.92,193.31 637.92,221.31 638.06,221.38 638.4,221.18 
		662.52,235.11 686.17,221.46 686.17,193.48 	"
        />
        <polygon
          className="st1"
          points="638.06,221.38 614.16,235.18 614.16,263.18 638.4,277.18 662.65,263.18 662.65,235.18 662.52,235.11 
		662.17,235.31 	"
        />
        <polygon
          className="st1"
          points="710.42,235.48 734.66,221.48 734.66,193.48 710.42,179.48 686.42,193.33 686.42,221.31 686.17,221.46 
		686.17,221.48 	"
        />
        <polygon
          className="st1"
          points="444.81,276.24 444.52,276.42 420.5,262.55 396.61,276.35 396.61,304.35 420.86,318.35 445.11,304.35 
		445.11,304.25 444.81,304.08 	"
        />
        <polygon
          className="st1"
          points="493.31,276.1 493.31,276.08 469.06,262.08 468.76,262.25 468.76,262.42 444.88,276.21 445.11,276.35 
		445.11,304.25 469.06,318.08 493.06,304.22 493.06,276.24 	"
        />
        <polygon
          className="st1"
          points="541.39,304.05 541.26,303.97 541.26,276.14 540.96,276.32 516.94,262.45 493.31,276.1 493.31,304.08 
		493.06,304.22 493.06,304.24 517.3,318.24 517.49,318.14 517.49,317.85 	"
        />
        <polygon
          className="st1"
          points="541.32,276.11 541.55,276.24 541.55,303.95 541.74,303.85 565.86,317.77 589.5,304.12 589.5,277.11 
		589.75,276.97 589.75,275.97 565.51,261.97 565.21,262.15 565.21,262.32 	"
        />
        <polygon
          className="st1"
          points="565.99,345.62 565.99,317.85 565.86,317.77 565.51,317.97 541.55,304.15 541.55,304.24 517.49,318.14 
		517.49,345.85 541.74,359.85 542.15,359.61 542.15,359.38 	"
        />
        <polygon
          className="st1"
          points="541.39,304.05 541.55,304.15 541.55,303.95 	"
        />
        <polygon
          className="st1"
          points="444.81,276.17 444.81,276.08 468.76,262.25 468.76,234.42 444.52,220.42 420.33,234.38 420.33,262.16 
		420.27,262.2 420.27,262.42 420.5,262.55 420.86,262.35 	"
        />
        <polygon
          className="st1"
          points="444.81,276.24 444.88,276.21 444.81,276.17 	"
        />
        <polygon
          className="st1"
          points="541.26,276.07 541.26,275.97 565.21,262.15 565.21,234.32 540.96,220.32 516.71,234.32 516.71,262.32 
		516.94,262.45 517.3,262.24 	"
        />
        <polygon
          className="st1"
          points="541.26,276.07 541.26,276.14 541.32,276.11 	"
        />
        <polygon
          className="st1"
          points="420.33,234.38 420.33,234.16 396.09,220.16 371.84,234.16 371.84,262.16 396.09,276.16 420.27,262.2 
		420.27,234.42 	"
        />
        <polygon
          className="st1"
          points="638.89,388.22 638.89,360.52 614.65,346.52 590.65,360.37 590.65,387.38 590.4,387.52 590.4,388.52 
		614.65,402.52 614.83,402.41 614.83,402.12 	"
        />
        <polygon
          className="st1"
          points="638.89,388.22 638.89,388.52 614.83,402.41 614.83,430.12 639.08,444.12 663.33,430.12 663.33,402.12 
		639.08,388.12 	"
        />
        <polygon
          className="st1"
          points="590.65,360.37 590.65,359.38 566.4,345.38 565.99,345.62 565.99,345.85 542.15,359.61 542.15,387.38 
		566.4,401.38 590.4,387.52 590.4,360.52 	"
        />
        <polygon
          className="st1"
          points="735.11,361 735.11,389 759.36,403 783.61,389 783.61,361 759.36,347 	"
        />
        <polygon
          className="st1"
          points="760.07,431.51 760.07,403.8 735.82,389.8 711.83,403.65 711.83,430.66 711.57,430.81 711.57,431.8 
		735.82,445.8 736.01,445.69 736.01,445.4 	"
        />
        <polygon
          className="st1"
          points="760.26,431.4 760.07,431.51 760.07,431.8 736.01,445.69 736.01,473.4 760.26,487.4 784.5,473.4 
		784.5,445.4 	"
        />
        <polygon
          className="st1"
          points="711.83,403.65 711.83,402.66 687.58,388.66 663.33,402.66 663.33,430.66 687.58,444.66 711.57,430.81 
		711.57,403.8 	"
        />
      </g>
    </svg>
  )
}

export default HoneycombBG
